import React, { Fragment, useEffect, useState } from "react";
import "./Modal.scss";
import { Tab, Tabs } from "react-bootstrap";
import { collection, addDoc } from "firebase/firestore";
import { db, mockSubmit } from "../services/firebase";

const foodChoicesOptions = {
  meat: "Месно меню",
  vegetarian: "Вегетарианско меню",
  kids: "Детско меню",
};

const alcohol = [
  "Вино - бяло",
  "Вино - червено",
  "Вино - просеко",
  "Бира",
  "Ракия",
  "Водка",
  "Уиски",
  "Джин",
];
const Modal = ({ accommodation, nPersons }) => {
  const [attendanceConfirmed, setAttendanceConfirmed] = useState(false);
  const [guestsInfo, setGuestsInfo] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [accommodationNights, setAccommodationNights] = useState(1);
  const [selectedAlcohol, setSelectedAlcohol] = useState([]);
  const [song, setSong] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitSuccesful, setIsSubmitSuccesful] = useState(false);

  useEffect(() => {
    const guestsInfoInitialState = Array(nPersons).fill({
      name: "",
      foodChoice: foodChoicesOptions.meat,
    });
    setGuestsInfo(guestsInfoInitialState);
  }, [nPersons]);
  const isFormInvalid = !guestsInfo?.[0]?.name || !phoneNumber.length;

  const handleGuestInfoChange = (fieldName, value, index = 0) => {
    setGuestsInfo((guestsInfo) => {
      const guestInfoArrayCopy = [...guestsInfo];
      guestInfoArrayCopy[index] = {
        ...guestInfoArrayCopy[index],
        [fieldName]: value,
      };
      return guestInfoArrayCopy;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const coll = collection(db, "guests");
    setIsLoading(true);
    // await mockSubmit();
    await addDoc(coll, {
      attendanceConfirmed,
      guestsInfo: guestsInfo.filter((guestInfo) => guestInfo.name.length > 0),
      phone: phoneNumber,
      ...(accommodation && { nights: accommodationNights }),
      ...(selectedAlcohol.length && { alcohol: selectedAlcohol }),
      ...(song.length && { song }),
    });
    setIsLoading(false);
    setIsSubmitSuccesful(true);
  };

  const handleAlcoholChange = (alcohol) => {
    if (selectedAlcohol.includes(alcohol)) {
      setSelectedAlcohol((selectedAlcohol) =>
        selectedAlcohol.filter((alcoholName) => alcoholName !== alcohol),
      );
    } else {
      setSelectedAlcohol((selectedAlcohol) => [...selectedAlcohol, alcohol]);
    }
  };

  return (
    <div
      className="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="true"
      data-bs-keyboard="true"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Потвърждаване на поканата
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          {!isSubmitSuccesful ? (
            <Fragment>
              <div className="modal-body">
                <div className="mb-4">
                  <p className="mb-0">
                    Моля потвърдете вашето присъствие като попълните формата до{" "}
                    <strong>30.05.2024г.</strong>{" "}
                  </p>
                  <p>
                    Полетата отбелязани с{" "}
                    <span className="required-field"></span> са задължителни!
                  </p>
                </div>

                <form
                  action=""
                  method="get"
                  className="form-attendance-container"
                >
                  <div className="form-attendance">
                    <label className="label-title required-field">
                      Ще присъствате ли на събитието?
                    </label>
                    <div className="radio">
                      <div className="radio-input form-check me-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="attendance-confirmed"
                          onChange={() => {
                            setAttendanceConfirmed(true);
                          }}
                          checked={attendanceConfirmed}
                        />
                        <label
                          htmlFor="attendance-confirmed"
                          className="form-check-label"
                        >
                          Да
                        </label>
                      </div>
                      <div className="radio-input form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          id="attendance-declined"
                          onChange={() => {
                            setAttendanceConfirmed(false);
                          }}
                          checked={!attendanceConfirmed}
                        />
                        <label
                          htmlFor="attendance-declined"
                          className="form-check-label"
                        >
                          Не
                        </label>
                      </div>
                    </div>
                  </div>

                  {!attendanceConfirmed ? (
                    <Fragment>
                      <div className="form-attendance">
                        <label
                          htmlFor="name"
                          className="label-title required-field"
                        >
                          Име и Фамилия{" "}
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          id="name"
                          onChange={(e) =>
                            handleGuestInfoChange("name", e.target.value)
                          }
                          value={guestsInfo?.[0]?.name}
                          required
                        />
                      </div>
                      <div className="form-attendance">
                        <label
                          htmlFor="phone"
                          className="label-title required-field"
                        >
                          Телефонен номер{" "}
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="phone"
                          id="phone"
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          value={phoneNumber}
                          required
                        />
                      </div>
                    </Fragment>
                  ) : (
                    <Tabs defaultActiveKey={0} id="uncontrolled-tab-example">
                      {guestsInfo.map((item, index) => (
                        <Tab
                          eventKey={index}
                          title={`Гост ${index + 1}`}
                          key={index}
                        >
                          <div className="form-attendance">
                            <label
                              htmlFor="name"
                              className="label-title pe-3 required-field"
                            >
                              Име и Фамилия{" "}
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="name"
                              id="name"
                              onChange={(e) =>
                                handleGuestInfoChange(
                                  "name",
                                  e.target.value,
                                  index,
                                )
                              }
                              value={item.name}
                              required
                            />
                          </div>
                          <div className="form-attendance">
                            <label
                              htmlFor="menu"
                              className="label-title required-field"
                            >
                              Какво меню ще консумирате?{" "}
                            </label>
                            <div className="check-input form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={foodChoicesOptions.meat + index}
                                id={foodChoicesOptions.meat + index}
                                checked={
                                  item.foodChoice === foodChoicesOptions.meat
                                }
                                value={foodChoicesOptions.meat}
                                onChange={() =>
                                  handleGuestInfoChange(
                                    "foodChoice",
                                    foodChoicesOptions.meat,
                                    index,
                                  )
                                }
                              />
                              <label
                                htmlFor={foodChoicesOptions.meat + index}
                                className="form-check-label"
                              >
                                {foodChoicesOptions.meat}{" "}
                              </label>
                            </div>
                            <div className="check-input form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={foodChoicesOptions.vegetarian + index}
                                id={foodChoicesOptions.vegetarian + index}
                                checked={
                                  item.foodChoice ===
                                  foodChoicesOptions.vegetarian
                                }
                                value={foodChoicesOptions.vegetarian}
                                onChange={() =>
                                  handleGuestInfoChange(
                                    "foodChoice",
                                    foodChoicesOptions.vegetarian,
                                    index,
                                  )
                                }
                              />
                              <label
                                htmlFor={foodChoicesOptions.vegetarian + index}
                                className="form-check-label"
                              >
                                {foodChoicesOptions.vegetarian}{" "}
                              </label>
                            </div>
                            <div className="check-input form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={foodChoicesOptions.kids + index}
                                id={foodChoicesOptions.kids + index}
                                checked={
                                  item.foodChoice === foodChoicesOptions.kids
                                }
                                value={foodChoicesOptions.kids}
                                onChange={() =>
                                  handleGuestInfoChange(
                                    "foodChoice",
                                    foodChoicesOptions.kids,
                                    index,
                                  )
                                }
                              />
                              <label
                                htmlFor={foodChoicesOptions.kids + index}
                                className="form-check-label"
                              >
                                {foodChoicesOptions.kids}{" "}
                              </label>
                            </div>
                          </div>
                        </Tab>
                      ))}
                    </Tabs>
                  )}

                  {attendanceConfirmed ? (
                    <Fragment>
                      {" "}
                      <div className="form-attendance">
                        <label
                          htmlFor="phone"
                          className="label-title required-field"
                        >
                          Телефонен номер{" "}
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="phone"
                          id="phone"
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          value={phoneNumber}
                          required
                        />
                      </div>
                      {accommodation ? (
                        <div className="form-attendance">
                          <label className="label-title">
                            Колко нощувки ще резервирате?
                          </label>
                          <div className="radio radio-accommodation">
                            <div className="radio-input form-check me-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={"accommodation" + 1}
                                id="accommodation-nights-1"
                                checked={accommodationNights === 1}
                                onChange={(e) => setAccommodationNights(1)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="accommodation-nights-1"
                              >
                                1 нощувка (24.08)
                              </label>
                            </div>
                            <div className="radio-input form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={"accommodation" + 2}
                                id="accommodation-nights-2"
                                checked={accommodationNights === 2}
                                onChange={(e) => setAccommodationNights(2)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="accommodation-nights-2"
                              >
                                2 нощувки (23-24.08/първата нощувка е за наша
                                сметка/)
                              </label>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="form-attendance">
                        <label htmlFor="alcohol" className="label-title">
                          Какъв алкохол бихте консумирали? (Моля отбележете
                          няколко, ако попълвате анкетата за повече от един гост
                          и избора ви за алкохол се различава)
                        </label>
                        <div className="check">
                          {alcohol.map((alcohol) => (
                            <div className="check-input">
                              <input
                                key={alcohol}
                                type="checkbox"
                                id={alcohol}
                                checked={selectedAlcohol.includes(alcohol)}
                                onChange={() => handleAlcoholChange(alcohol)}
                              />
                              <label htmlFor={alcohol}>{alcohol} </label>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="form-attendance">
                        <label htmlFor="song" className="label-title">
                          Коя песен гарантирано ще ви накара да излезете на
                          дансинга?
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          name="song"
                          id="song"
                          value={song}
                          onChange={(e) => setSong(e.target.value)}
                        />
                      </div>
                    </Fragment>
                  ) : null}
                </form>
              </div>
              <div className="modal-footer">
                <div className="btn-container">
                  <button
                    type="button"
                    className="d-flex align-items-center justify-content-center btn btn-confirm btn-dark me-3"
                    onClick={handleSubmit}
                    disabled={isLoading || isFormInvalid}
                  >
                    {isLoading ? (
                      <div className="spinner" />
                    ) : (
                      "Изпращане на формата"
                    )}
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-dark"
                    data-bs-dismiss="modal"
                  >
                    Отказ
                  </button>
                </div>
              </div>
            </Fragment>
          ) : (
            <div className="modal-body text-center pb-5">
              <div>
                <h4>
                  Благодарим ви за отделеното време! Разгледайте нашия уебсайт
                  за допълнителна информация.
                </h4>
                <h6 className="mt-3">
                  Ако имате въпроси, не се колебайте да се свържете с нас!
                </h6>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
