import calendar from "./pics/calendar.gif";
import ringHands from "./pics/ring-hands.jpg";
import venue1 from "./pics/venue-1.jpg";
import venue2 from "./pics/venue-2.jpg";
import church from "./pics/church.jpg";
import beach from "./pics/beach.jpg";
import kotor1 from "./pics/kotor.jpg";
import kotor3 from "./pics/kotor3.jpg";
import room from "./pics/room.jpg";
import beachWalk from "./pics/beach-walk.jpg";
import ringKiss from "./pics/ring-kiss.jpg";

import "./App.scss";
import React, { Fragment, useEffect, useState } from "react";
import Program from "./components/Program";
import Modal from "./components/Modal";

const App = () => {
  const [accommodation, setAccommodation] = useState(false);
  const [nPersons, setNPersons] = useState();
  const [disableSubmission, setDisableSubmission] = useState(true);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const qParam = urlParams.get("gd");
    if (qParam) {
      setDisableSubmission(false);
      const personsCount = Number(qParam[0]);
      if (qParam) {
        if (qParam[1] === "t") {
          setAccommodation(true);
        } else {
          setAccommodation(false);
        }
        setNPersons(personsCount > 5 ? 1 : personsCount);
      }
    }
  }, [accommodation]);

  return (
    <div className="container-home">
      <div className="image-container-home">
        <div className="picture"></div>
        <div className="overlay"></div>

        <ul className="navbar stroke">
          <li>
            <a className="nav" href="#program">
              ПРОГРАМА
            </a>
          </li>
          <li>
            <a className="nav" href="#location">
              ЛОКАЦИЯ
            </a>
          </li>
          <li>
            <a className="nav" href="#questions">
              ВЪПРОСИ
            </a>
          </li>
        </ul>
        <div className="text-container centered">
          <div className="text-flex text-enter-up">
            <h1 className="nautugal nie">Hue</h1>
          </div>
          <div className="text-flex text-enter-up">
            <h1 className="cormorant names">ЕЛЕНА</h1>
            <h1 className="nautugal">u</h1>
            <h1 className="cormorant names">АЛЕКСАНДЪР</h1>
          </div>
          <div className="text-flex text-enter-down">
            {" "}
            <h4 className="montserrat kanim">Ви каним на нашата сватба</h4>
            <h2 className="cormorant">24 | 08 | 24</h2>
          </div>
          <div className="text-flex text-enter-down">
            {" "}
            <h4 className="montserrat selo">с. Лещен</h4>
          </div>
        </div>
      </div>
      <section className="section">
        <div className="calendar-name-container">
          <h1 className="calendar-name nautugal">Abrycm</h1>
        </div>

        <div className="container calendar-div-container">
          <img src={kotor1} alt="" className="img-fluid" />
          <img src={calendar} alt="" className="calendar img-fluid" />
          <img src={kotor3} alt="" className="img-fluid" />
        </div>
        <div className="calendar-container-div-mobile container d-lg-none">
          <div className="row2">
            <img src={calendar} alt="" className="calendar img-fluid" />
          </div>
          <div className="row1">
            <img src={kotor1} alt="" className="img-fluid" />
            <img src={kotor3} alt="" className="img-fluid" />
          </div>
        </div>
      </section>

      <section className="section" id="location">
        <div className="title-container">
          <h2 className="section-title mt-5 ">Локация</h2>
        </div>

        <div className="image-container-location container">
          <div className="location-images">
            <img src={venue1} alt="" className="img-fluid " />
            <img src={church} alt="" className="img-fluid dsmnone" />
            <img src={venue2} alt="" className="img-fluid " />
          </div>
          <div className="location-text container">
            <p className="my-3">
              Църковният ритуал ще се проведе в малката и китна църква Света
              Параскева, която е построена през 1833 година.
            </p>
            <p className="my-3">
              Нашето тържество ще се състои в с. Лещен, намиращо се в Западните
              Родопи, на около 200 км от град София. Лещен е известен със своите
              красиви панорамни гледки към Родопи и Пирин, както и със старите
              къщи в селото, запазили автентичния си архитектурен стил. Наоколо
              може да се насладите на различни забележителности като с.
              Ковачевица, Глинената къща, водопад Сини вир, античен римски град
              "Никополис ад Нестум", гр. Гоце Делчев, с.Делчево и други.
              Близостта до южната ни съседка също предразполага за една последна
              морска почивка, веднага след нашата сватба, за щастлив завършек на
              лятото.
            </p>
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11927.720272348386!2d23.8277606!3d41.635643!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14ab969a3ea13c99%3A0xd57051b644f67251!2z0KDQvtC00L7Qv9GB0LrQuCDQutGK0YI!5e0!3m2!1sbg!2sbg!4v1710692476485!5m2!1sbg!2sbg"
                width="600"
                height="450"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="iframe-map"
                title="iframe-title"
              ></iframe>
            </div>
            <p className="mt-4 d-flex justify-content-center flex-column align-items-center links">
              <strong className="mb-lg-3">Полезни линкове:</strong>
              <div>
                <a
                  target="_blank"
                  href="https://www.google.com/maps/place/%D0%A0%D0%BE%D0%B4%D0%BE%D0%BF%D1%81%D0%BA%D0%B8+%D0%BA%D1%8A%D1%82/@41.635643,23.8277606,15z/data=!4m9!3m8!1s0x14ab969a3ea13c99:0xd57051b644f67251!5m2!4m1!1i2!8m2!3d41.635643!4d23.8277606!16s%2Fg%2F11fxdxy4k8?entry=ttu"
                  rel="noreferrer"
                >
                  Локация на комплекса
                </a>
                <a
                  target="_blank"
                  href="https://rodopskikat.com/"
                  rel="noreferrer"
                  className="ms-4"
                >
                  Сайт на комплекса
                </a>
              </div>
            </p>
          </div>
        </div>
      </section>

      <section className="section beige" id="program">
        <div className="title-container">
          <h2 className="section-title mt-5 ">ПРОГРАМА</h2>
          <h4 className="section-subtitle text-green">на нашия сватбен ден</h4>
        </div>
        <Program />
      </section>
      {/*<div className="parallax-container">*/}
      {/*  <div*/}
      {/*    className="parallax"*/}
      {/*    style={{ backgroundImage: `url(${ringHands})` }}*/}
      {/*  ></div>*/}
      {/*  <div className="overlay">*/}
      {/*    <div className="overlay-title">*/}
      {/*      <h1 className="cormorant mt-5 ">КУМУВАТ</h1>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className="text-container ">*/}
      {/*  <div className="kumove">*/}
      {/*    <div className="kumove-title">*/}
      {/*      <h4 className="montserrat text-green">Станислава Стефанова</h4>*/}
      {/*      <h3 className="nautugal text-green">U</h3>*/}
      {/*      <h4 className="montserrat text-green">Йордан Йорданов</h4>*/}
      {/*    </div>*/}
      {/*    <div className="image-container-cropped">*/}
      {/*      <img src={sisiCropped} alt="" className="img-fluid" />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}

      {accommodation ? (
        <section className="section">
          <div className="title-container">
            <h2 className="section-title mt-5 ">НАСТАНЯВАНЕ</h2>
          </div>

          <div className="text-container ">
            <div className="text-container-paragraph mx-5">
              <div className="container">
                <div className="row">
                  <div
                    className="col-md-4  mb-2 d-flex"
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      height: "100%",
                    }}
                  >
                    {/*TODO change pic and improve vertical spacing*/}
                    <img src={room} alt="" className="img-fluid pt-2" />
                  </div>
                  <div className="col-md-8">
                    <p>
                      Нощувката в сватбения ни ден е комплимент от нас. В
                      комплекс Родопски кът ще можете да се насладите на красиви
                      панорамни гледки, външен басейн и спа зона.
                    </p>
                    <p className="my-3 ">
                      Добре дошли сте да се присъедините към нас още в петък, за
                      да споделим един дълъг уикенд заедно. В този случай,
                      първата нощувка се заплаща от гостите и е в размер на 60
                      лева на човек. Може да бъде заплатена на рецепция при
                      настаняване.
                    </p>
                    <p className="my-3 ">
                      Във формата за потвърждение на присъствие може да
                      отбележите кога предпочитате да се включите.{" "}
                    </p>
                    <p className="my-3 ">
                      Настаняването започва от 14 ч, а освобождаването на стаите
                      в неделя е най-късно до 12 ч. Към нощувката има включена
                      закуска за всички гости на хотела.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      <section className="section beige">
        <div className="gallery-container-div container ">
          <img src={beachWalk} alt="" className="img-fluid" />
          <img src={ringHands} alt="" className=" img-fluid" />
          <img src={ringKiss} alt="" className="img-fluid" />
        </div>
        <div className="gallery-container-div-mobile container d-lg-none">
          <div className="row1">
            <img src={beachWalk} alt="" className="img-fluid" />
            <img src={ringKiss} alt="" className="img-fluid" />
          </div>
          <div className="row2">
            <img src={ringHands} alt="" className=" img-fluid" />
          </div>
        </div>
      </section>

      <section
        className={`section ${accommodation ? "beige" : ""}`}
        id="questions"
      >
        <div className="title-container">
          <h2 className="section-title mt-5 ">Въпроси</h2>
        </div>

        <div className="text-container">
          <div className="text-container-paragraph  mx-5">
            <div className="container">
              <div className="my-3">
                <p className="fw-bold mb-1">
                  Има ли краен срок за потвърждение на присъствие?{" "}
                </p>
                <p className="my-0">Да, моля да потвърдите преди 30.05.2024.</p>
              </div>
              <div className="my-3">
                <p className="fw-bold mb-1">
                  Ако не мога да присъствам, трябва ли да ви уведомя?
                </p>
                <p className="my-0">
                  Със сигурност ще ни липсвате! Да, моля да ни уведомите чрез
                  формата за потвърждение по-надолу, или се свържете с нас
                  лично.{" "}
                </p>
              </div>
              <div className="my-3">
                <p className="fw-bold mb-1">
                  Можете ли да ни дадете насоки за подарък?
                </p>
                <p className="my-0">
                  Присъствието ви е най-големия подарък за нас! Все пак бихме
                  оценили ако помогнете за нашето сватбено пътешествие с подарък
                  в плик.
                </p>
              </div>
              <div className="my-3">
                <p className="fw-bold mb-1">
                  Да носим ли букет в деня на сватбата?
                </p>
                <p className="my-0">
                  Бихме предпочели да не носите цветя. Вместо цвете или букет,
                  ще можете да направите скромно дарение за благотворителна
                  кауза.
                </p>
              </div>
              <div className="my-3">
                <p className="fw-bold mb-1">Има ли дрескод? </p>
                <p className="my-0">
                  Сватбата ще бъде градинска, затова ви препоръчваме да носите
                  връхна дреха, тъй като все пак ще бъдем в планината. За дамите
                  ще бъде по-комфортно ако не носят тънки токчета. Относно
                  дрескод, няма проблем да се облечете, както пожелаете.
                </p>
              </div>
              <div className="my-3">
                <p className="fw-bold mb-1">
                  Ако все още имате въпроси, не се колебайте да се свържете с
                  нас!{" "}
                </p>
                <p className="my-0">Нашите телефонни номера:</p>
                <p className="my-0">Александър - 0886162404 </p>
                <p className="my-0">Елена - 0896735554 </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="image-container">
        <img
          src={beach}
          alt=""
          className="img-fluid"
          style={{ width: "100%" }}
        />
        {!disableSubmission && (
          <Fragment>
            <button
              type="button"
              className="centered btn btn-one"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              ПОТВЪРДЕТЕ ПРИСЪСТВИЕ
            </button>
            {/* <!-- Modal --> */}
            <Modal
              accommodation={accommodation}
              nPersons={nPersons}
              id="staticBackdrop"
            ></Modal>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default App;
