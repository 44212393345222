import React from "react";
import churchPicto from "../pics/church_picto.png";
import toastPicto from "../pics/toast_picto.png";
import ringsPicto from "../pics/two-rings_picto.png";
import cameraPicto from "../pics/camera_picto.png";
import dishPicto from "../pics/dish_picto.png";
import ballPicto from "../pics/mirror-ball_picto.png";
import "./Program.scss";
const Program = () => {
  return (
    <div className="program-container">
      <div className="graphic-container ">
        <div className="graphic-row">
          <div className="graphic-row-left">
            <div className="hour">
              <h2 className="fw-bold cormorant">15:00</h2>
            </div>
            <div className="location">
              <h3 className="fw-bold cormorant">Църковен брак</h3>
              <p className="montserrat">храм Св. Параскева</p>
            </div>
          </div>
          <div className="graphic-row-right ">
            <img src={churchPicto} alt="" className="img-fluid turn-white" />
          </div>
        </div>
        <div className="graphic-row reversed">
          <div className="graphic-row-left reversed">
            <div className="hour">
              <h2 className="fw-bold cormorant">17:00</h2>
            </div>
            <div className="location">
              <h3 className="fw-bold cormorant">Коктейл </h3>
              <p className="montserrat">к-с Родопски кът</p>
            </div>
          </div>
          <div className="graphic-row-right reversed">
            <img src={toastPicto} alt="" className="img-fluid turn-white" />
          </div>
        </div>
        <div className="graphic-row">
          <div className="graphic-row-left">
            <div className="hour">
              <h2 className="fw-bold cormorant">17:30</h2>
            </div>
            <div className="location">
              <h3 className="fw-bold cormorant">Изнесен ритуал </h3>
              <p className="montserrat">к-с Родопски кът </p>
            </div>
          </div>
          <div className="graphic-row-right">
            <img src={ringsPicto} alt="" className="img-fluid turn-white" />
          </div>
        </div>
        <div className="graphic-row reversed">
          <div className="graphic-row-left reversed">
            <div className="hour">
              <h2 className="fw-bold cormorant">18:00</h2>
            </div>
            <div className="location">
              <h3 className="fw-bold cormorant">Поздравления</h3>
              <p className="montserrat">к-с Родопски кът</p>
            </div>
          </div>
          <div className="graphic-row-right reversed">
            <img src={cameraPicto} alt="" className="img-fluid turn-white" />
          </div>
        </div>
        <div className="graphic-row">
          <div className="graphic-row-left">
            <div className="hour">
              <h2 className="fw-bold cormorant">19:00</h2>
            </div>
            <div className="location">
              <h3 className="fw-bold cormorant">Вечеря </h3>
              <p className="montserrat">к-с Родопски кът </p>
            </div>
          </div>
          <div className="graphic-row-right">
            <img src={dishPicto} alt="" className="img-fluid turn-white" />
          </div>
        </div>
        <div className="graphic-row reversed">
          <div className="graphic-row-left reversed">
            <div className="hour">
              <h2 className="fw-bold cormorant">19:30</h2>
            </div>
            <div className="location">
              <h3 className="fw-bold cormorant">Парти </h3>
              <p className="montserrat">к-с Родопски кът</p>
            </div>
          </div>
          <div className="graphic-row-right reversed">
            <img src={ballPicto} alt="" className="img-fluid turn-white" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Program;
